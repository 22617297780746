import SocialMedia from "./social-media";


function ProjektujInfo({content}) {

  const displayForm = true;

    return (
      <div className="ProjektujInfo">
          <h2>{content.projektujInfo1}</h2>
          <p>{content.projektujInfo2}</p>
          <p className="highlight">{content.projektujInfo3}</p>
          <div className="ProjektujInfo-instruction">
            <div className="glass shadow">
              <p>{content.projektujInfo4}</p>
            </div>
            <div>
              <svg width="12.753mm" height="9.7553mm" version="1.1" viewBox="0 0 12.753 9.7553" xmlns="http://www.w3.org/2000/svg"><g transform="translate(-527.31 -74.734)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth=".6"><path d="m527.61 75.457c1.7287-0.52546 3.6719-0.64252 5.3524 0.12807 1.6274 0.74624 2.7529 2.2748 3.4031 3.9041 0.56224 1.4089 0.83097 2.9214 0.92111 4.431"/><path d="m539.77 79.623c-0.49953 1.2263-0.97255 2.4775-1.6728 3.6035-0.20409 0.32816-0.39002 0.69473-0.71129 0.9228-0.21926 0.15566-0.43905-0.08582-0.57538-0.23636-0.60609-0.66928-1.0911-1.4363-1.5674-2.1999-0.4052-0.64966-0.79612-1.3083-1.1739-1.9742"/></g></svg>
            </div>
            <div className="glass shadow ProjektujInfo-social-media">
              <p>{content.projektujInfo5}</p>
              <SocialMedia displayForm={true} content={content}/>
            </div>
          </div>
      </div>
    );
  }  
  export default ProjektujInfo;