import {Routes, Route, useLocation} from "react-router-dom";
import React, {useState, useEffect} from 'react';
import Nav from "./components/Nav"
import HopUp from "./components/HopUp"
import Urlop from "./components/Urlop"
import UrlopModal from "./components/UrlopModal"
import Home from "./components/Home";
import Projektuj from "./components/Projektuj"
import ModalForLinear3 from "./components/modal-for-linear3"
import ContactUs from "./components/ContactUs"
import Footer from "./components/Footer"
import UszytePerelki from "./components/UszytePerelki"
import Talizman from "./components/Talizman"
import NotFound from "./components/NotFound"
import {pl, en} from "./ContentLang"
import BagsToBuy from "./components/homeComponents/BagsToBuy";




function App() {

  const [showUrlop, setShowUrlop] = useState(true)
  
  const [windowSize, setWindowSize] = useState(window.innerWidth);


  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  const [lang, setLang] = useState("pl");
  const [content, setContent] = useState({})

  document.documentElement.setAttribute('lang', lang);

  const langLocal = JSON.parse(localStorage.getItem('lang'));

useEffect(() => {
  if(langLocal && langLocal=== lang){
    return;
  }else if(langLocal && langLocal !== lang){
    setLang(langLocal)
  }
  else if(!langLocal){
    localStorage.setItem('lang', JSON.stringify(lang));
  }

  if(lang === "pl"){
    setContent(pl)
  }else if(lang === "en"){
    setContent(en)
  }
}, []);

useEffect(() => {
  if(langLocal && langLocal !== lang){
    localStorage.setItem('lang', JSON.stringify(lang));
  }

  if(lang === "pl"){
    setContent(pl)
  }else if(lang === "en"){
    setContent(en)
  }
},[lang])


  return (
    <div className="App">
      {/* <Urlop windowSize={windowSize} lang={lang} content={content}/>  */}
      <Nav windowSize={windowSize} lang={lang} setLang={setLang} content={content}/>
      {/* {showUrlop && <UrlopModal setShowUrlop={setShowUrlop} lang={lang} content={content}/>} */}
      <Routes>
        <Route path={`/`} element={<Home windowSize={windowSize} lang={lang} content={content} />} />
        <Route path='/dostepne-od-reki' element={<BagsToBuy lang={lang} content={content}/>}/>
        <Route path='/projektuj' element={<Projektuj lang={lang} content={content}/>}  >
          <Route path=":id" element={<ModalForLinear3 lang={lang} content={content} windowSize={windowSize}/>} />  
        </Route>
        <Route path='/uszyte-perelki' element={<UszytePerelki lang={lang} content={content} windowSize={windowSize}/>}/>
        <Route path='/talizman' element={<Talizman/>} />
        <Route path='/*' element={<NotFound />} />
      </Routes>
      <HopUp content={content}/>
      <ContactUs content={content} windowSize={windowSize}/>
      <Footer />
    </div>
  );
}

export default App;
