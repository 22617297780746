import React, { useRef, useState,useEffect } from 'react';
import emailjs from '@emailjs/browser';
import {motion } from 'framer-motion';
import SocialMedia from './social-media'

 function ContactUs({content}) {
   const [formVisible, setFormVisible] = useState(true)
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_pn651el', 'template_g8m9a8k', form.current, '0DOjVqy-LBFwgFei1')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset();
      setFormVisible(false)
  };

  return (
    <section id="contact" className='ContactUs'>
      <h2 className='section-header'>{content.contact1}</h2>
      <div className='ContactUsWrapper glass'>
        <div className="ContactUs-text">
          <div className="ContactUs-info">
            <h3>{content.contact2}</h3>
            <p>{content.contact3}</p>
            <p>{content.contact4}</p>
          </div>
          <div>
          <p className='social-media-contact-text'>{content.contact6}</p>
          <SocialMedia displayForm={false} content={content}/>
          </div>
        </div>
        {formVisible ? 
          <motion.form ref={form} onSubmit={sendEmail} className='ContactUs-form' initial={{opacity:0}} animate={{opacity:1}} transition={{duration: 0.3}}>
            <label>{content.contact7}</label>
            <input type="text" name="user_name" required placeholder=" "/>
            <label>{content.contact8}</label>
            <input type="email" name="user_email" required placeholder=" " />
            <label>{content.contact9}</label>
            <textarea name="message" required placeholder=" " />
            <button type="submit">{content.contact10}</button>
          </motion.form>
        :
          <motion.div className='ContactUs-thanks' initial={{opacity:0}} animate={{opacity:1}} transition={{duration: 0.3}}>
            <p>{content.contact11}</p>
            <p>{content.contact12}</p>
            <p>{content.contact13}</p>
            <button onClick={() =>setFormVisible(true)}>{content.contact14}</button>
          </motion.div> }
      </div>
    </section>
  );
};
export default ContactUs;