
function ToggleLang({lang, setLang}) {
    const activeStyles = {
        filter: "grayScale(0)"
    }
    return (
    <div className="ToggleLang">
        <button style={lang === "pl" ? activeStyles : {}} className="pl-button lang-button" onClick={() => setLang("pl")}></button>
        <button style={lang === "en" ? activeStyles : {}} className="en-button lang-button" onClick={() => setLang("en")}></button>
    </div>
    );
  }
  
  export default ToggleLang;