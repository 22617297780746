import {modele} from "../../modele-obj.js";
import { motion} from "framer-motion";
import {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import Logo from "../../img/logo-zszyte.svg"
import {fromLeft,fadingIn} from "../../myVariants"
import prevBtn from "../../img/prev-arrow.svg"
import nextBtn from "../../img/next-arrow.svg"



function Modele({windowSize,content,lang}) {
    const [carouselPosition, setCarouselPosition] = useState(0);
    const carouselElementWidth = windowSize < 670 ? 100 : 60; //vw = mod-outer-carousel width
    const timerAnimationTime = 8000; // slide animation
    const fadeOutAnimationDelay = (timerAnimationTime/1000) - 0.2 // 0.2 is duration of fading animation

    let carouselTimer
    useEffect(() => {
        carouselTimer = setTimeout(() =>{
            if(carouselPosition === 0 ){
                setCarouselPosition(carouselElementWidth)
            }else if(carouselPosition < (modele.length - 1)*carouselElementWidth){
                setCarouselPosition((prev) => prev + carouselElementWidth)
            }else if(carouselPosition === (modele.length - 1)*carouselElementWidth){
                setCarouselPosition(0)
            }
        },timerAnimationTime)
        return () => clearTimeout(carouselTimer)
    },[carouselPosition]);


    const forWhiteEl = {
        ini:{
            opacity:0
        },
        ani:{
            opacity:1,
            transition:{
                delay:0.5,
                duration:0.3,
                type:"easeIn"
            }
        }
    }

    const prev = () =>{
        clearTimeout(carouselTimer)
    setCarouselPosition((prev) => prev - carouselElementWidth)
    }
    const next = () => {
        clearTimeout(carouselTimer)
        setCarouselPosition((prev) => prev + carouselElementWidth)
    }


    return (
      <div className="HeroModele">
            <motion.div className="mod-content" variants={fadingIn} initial="hidden" animate="show" >
                <img className="mod-content-logo" src={Logo} alt="logo-zszyte"/>
                <h2>{content.hero1}</h2>
                <div className="mod-content-text">
                    <p>{content.hero2}</p>
                    <p>{content.hero3}</p>
                    <p>{content.hero4}</p>
                </div>
            </motion.div>


          <div className="mod-outer-carousel">
          <div className="carousel-controls">
                    <div className={`carousel-prev ${carouselPosition === 0 ? "disabled" : ""}`} onClick={prev}><img src={prevBtn} alt="" /></div>
                    <div className={`carousel-next ${carouselPosition === (modele.length - 1)*carouselElementWidth ? "disabled" : ""}`} onClick={next}><img src={nextBtn} alt="" /></div>
                </div>
            <motion.div className="mod-inner-carousel" animate={{x:`-${carouselPosition}vw`}} transition={{ duration: 0.6, type:"easeIn"}}>
                {modele.map((model,index) => {
                        return(
                            <motion.div className="ultimate-mod-wrapper" key={model.name} initial={{opacity:0}} whileInView={{opacity:1}} transition={{delay: 0.3, duration:0.2, type:"easeIn"}}>
                                <motion.div className="pretty-wrapper" initial="ini" whileInView="ani" variants={forWhiteEl}></motion.div>
                                <motion.div className="mod-wrapper" style={{background:`linear-gradient(90deg, ${model.firstColor}, ${model.secondColor})`}}>
                                    <img src={model.image} alt={model.name} />
                                    <div className="mod-info">
                                        <motion.h2 initial="ini" whileInView="ani" variants={forWhiteEl}>{model.name}</motion.h2>
                                        {lang === "pl" && <motion.p className="mod-info-paragraph" initial="ini" whileInView="ani" variants={forWhiteEl} >{model.info}</motion.p>}
                                    </div>
                                </motion.div>
                            </motion.div>
                        )
                    })
                }
            </motion.div>
          </div>
      </div>
    );
  }
  
  export default Modele;