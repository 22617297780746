import {useState,useEffect} from "react"
import { motion } from 'framer-motion'
import {Link, useParams,useNavigate} from "react-router-dom";
import close from "../img/close-x.svg"


function ModalForLinear3({content,windowSize}) {
  const {id} = useParams()
  const [properModal, setProperModal] = useState(false)
  const [startOrEnd, setStartOrEnd] = useState("start")

  const models = [ "ambasadorka", "atomówka", "pan plecak", "pani beczułka", "debiutantka", "aligatorka", "arystokratka", "konduktorka", "podróżniczka", "kuferek", "kompaktówka", "okruszek", "nerka"]

  const navigate = useNavigate();
  useEffect(() => {
    if(models.includes(id)){
      setProperModal(true)
    }else{
      navigate("/projektuj")
    }
  }, [id]);

  useEffect(() => {
    const start = models.slice(0,7)
    const end = models.slice(7)
    if(windowSize > 669 || start.includes(id)){
      setStartOrEnd("start")
    }else if(windowSize < 669 && end.includes(id)){
      setStartOrEnd("end")
    }
  }, []);

  return (
    <>
      {properModal && <motion.div  className="ModalForLinear3" initial={{opacity:0}} animate={{ opacity:1}} transition={{duration:0.3}} >
        <motion.div className="example-container" >
          <div className="model-example-header">
            <h2 >{id}</h2>
            <Link to="/projektuj">
              <img src={close} className="example-close" alt=""/>
            </Link>

          </div>


          <motion.div className="all-models-example" drag={windowSize < 669 && "x"} dragConstraints={{right: 0, left: -windowSize * 0.9}} initial={{x:startOrEnd === "start" ? 0 : -windowSize * 0.9}} transition={{duration:1, delay:1}}>
          {content.LinearModelData.list.map((item)=>{
            return(
              <Link to={`/projektuj/${item}`}  key={item} className={id === item ? "linear-example active-example" : "linear-example not-active-example"}>
                <svg className='linear-example-svg' version="1.1" viewBox="19 0 45 70" xmlns="http://www.w3.org/2000/svg">
                  <title>{item}</title>
                  <g transform= {content.LinearModelData[item].transform} className={item} fill="none" stroke={id === item ? "#fff" : "#cedbed"}>
                  <path d={content.LinearModelData[item].d1[0]} />

                  <path d={content.LinearModelData[item].d2[0]} />

                  <path d={content.LinearModelData[item].d3[0]} />

                  {content.LinearModelData[item].d4 && <path d={content.LinearModelData[item].d4[0]} />}

                  </g>
                </svg>     
              </Link>       
               )
          })}
          </motion.div>

          <h2 className="features-header">{content.linearModal1}</h2>
          <div className="model-example-size model-example-section">
            <div className="first-half model-example-size-svg">
              <svg className='size-linear size-linear-drawing' version="1.1" viewBox="0 0 84.36 75.709" xmlns="http://www.w3.org/2000/svg">
              <g transform= {content.LinearModelData[id].transform} fill="none" stroke="#fff">
              <motion.path d={content.LinearModelData[id].d1[0]} 
                            strokeWidth={content.LinearModelData[id].d1[1]} 
                            initial={{ pathLength: 0 }}
                            animate={{ pathLength: 1 }}
                            transition={{duration:2}}
                            key={id + "1"}/>

                <motion.path d={content.LinearModelData[id].d2[0]} 
                            strokeWidth={content.LinearModelData[id].d2[1]} 
                            initial={{ pathLength: 0 }}
                            animate={{ pathLength: 1 }}
                            transition={{duration:2}}
                            key={id + "2"}/>

                <motion.path d={content.LinearModelData[id].d3[0]} 
                            strokeWidth={content.LinearModelData[id].d3[1]} 
                            initial={{ pathLength: 0 }}
                            animate={{ pathLength: 1 }}
                            transition={{duration:2}}
                            key={id + "3"}/>
                    
                {content.LinearModelData[id].d4 && <motion.path d={content.LinearModelData[id].d4[0]} 
                            strokeWidth={content.LinearModelData[id].d4[1]} 
                            initial={{ pathLength: 0 }}
                            animate={{ pathLength: 1 }}
                            transition={{duration:2}}
                            key={id + "4"}/>}
              </g>
            </svg>

              <motion.svg className='size-linear' version="1.1" viewBox="0 0 84.36 75.709" xmlns="http://www.w3.org/2000/svg"
              initial={{opacity:0}} animate={{opacity:1}} transition={{delay:2, duration:0.3}} key={id + "5"}>
                <g transform="translate(0 0)">
                  <path d={content.LinearModelData[id].dd} color="#000000" fill="#fff"/>
                </g>
              </motion.svg>
            </div>
            <div className="size-text second-half">
              <p className="model-text model-text-color">{content.linearModal2} </p>
              <p className="model-text">{content.LinearModelData[id].wielkosc}</p>
            </div>
          </div>

          <h2 className="features-header">{content.linearModal3}</h2>
          <div className="model-example-variants model-example-section">
            {content.LinearModelData[id].warianty.map((variant,index)=>{
              return(
                <div className="variant">
                  <p className="variant-name">{variant}</p>
                  <img className="variant-img" src={`/modele-variants/${id}${index+1}.jpg`} alt="" />
                  <div className="variant-price"><p>{content.LinearModelData[id].ceny[index]}€</p></div>
                </div>
              )
            })}
          </div>

          <h2 className="features-header">{content.linearModal4}</h2>
          <div className="model-example-section model-example-features">
              <div className="features-item">
                <img className="features-actual-img" src={`/modele-features/${id}1.jpg`} alt="" />
              </div>
              <div className="features-item">
                <p className="features-text model-text">{content.LinearModelData[id].specyfikacja[0]}</p>
              </div>
              <div className="features-item">
                <img className="features-actual-img" src={`/modele-features/${id}2.jpg`} alt="" />
              </div>
              <div className="features-item">
                <p className="features-text model-text">{content.LinearModelData[id].specyfikacja[1]}</p>
              </div>
              <div className="features-item">
                <img className="features-actual-img" src={`/modele-features/${id}3.jpg`} alt="" />
              </div>
              <Link className="features-item" to={`/uszyte-perelki?type=${id}`}>
                 <p className="features-text model-text">{content.linearModal5}</p>
                 <svg className="arrow-to-perelki" width="21.622mm" height="17.772mm" version="1.1" viewBox="0 0 21.622 17.772" xmlns="http://www.w3.org/2000/svg">
                   <g transform="translate(2114.3 -165.74)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1.5">
                     <path d="m-2113.5 174.62h19.584"/>
                     <path d="m-2101.5 166.49 8.136 8.136-8.1359 8.1359" stopColor="#000000" strokeLinejoin="round"/>
                    </g>
                 </svg>
              </Link>
          </div>

        </motion.div>
      </motion.div>}
      </>
  );
}

  
  export default ModalForLinear3;