export const fromLeft = {
    hidden: {
      opacity:0,
      x: -300,
    },
    show:{
      opacity:1,
      x:0,
      transition:{
        type:'spring',
        delay:0.3,
        stiffness:90
      }
    }
  }

  export const fromRight = {
    hidden: {
      opacity:0,
      x: 300,
    },
    show:{
      opacity:1,
      x:0,
      transition:{
        type:'spring',
        delay:0.5,
        stiffness:90
      }
    }
  }

  export const fromBottom = {
    hidden: {
      opacity:0,
      y: 300,
    },
    show:{
      opacity:1,
      y:0,
      transition:{
        type:'spring',
        delay:0.5
      }
    }
  }

  export const fadingIn = {
    hidden: {
      opacity:0
    },
    show:{
      opacity:1,
      transition:{
        type:'easeIn',
        delay:0.5
      }
    }
  }


  export const forNavContainer = {
    show: {
      transition: {
        staggerChildren: 0.2
      }
    }
  }

  export const forNavItem = {
    hidden: {
      opacity:0,
      x: 200,
    },
    show:{
      opacity:1,
      x:0,
      transition:{
        type: 'easeIn'
      }
    }
  }

  export const forLinearModel = {
    init: {
      pathLength:0
    },
    show:{
      pathLength: 1,
      transition:{
        duration:2
    }
    }
  }

  export const forLinearModelImg = {
    init: {
      opacity:0
    },
    show: {
      opacity:1,
      transition:{
        duration:0.3,
        delay:1.9
    }
    }
  }

  export const ModelCarousel = {
    ani: position => {
        return{
            x: `${-position}vw`
        }
    }
  }

