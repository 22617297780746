import {useEffect, useState,useRef} from "react"
import { motion, AnimatePresence } from 'framer-motion'
import close from "../img/close-x.svg"
import prevBtn from "../img/prev-arrow.svg"
import nextBtn from "../img/next-arrow.svg"

function GalleryModal({galleryIndex, setGalleryIndex,filtred, windowSize}) {

    const [loading, setLoading] = useState(true);

    const prev = () => {
        if(galleryIndex === 0){
            setGalleryIndex(-1)
        } else if(galleryIndex > -1 && galleryIndex < filtred.length){
            setGalleryIndex((prev) => prev - 1)
        }
        setLoading(true)
    }

    const next = () => {
        if(galleryIndex === filtred.length -1){
            setGalleryIndex(-1)
        } else if(galleryIndex > -1 && galleryIndex <= filtred.length -1){
            setGalleryIndex((prev) => prev + 1)
        }
        setLoading(true)
    }

    const exit = () => {
        setGalleryIndex(-1)
    }


    const swipeVariants = {
        hidden:{
            x:200
        },
        show:  {
            x:0,
            transition:{
                duration :0.5
            }
        }
    }

    return (
        <motion.div className="GalleryModal" initial={{opacity:0}} animate={{opacity:1}} exit={{opacity:0}}>
            {galleryIndex > -1 &&<div className="controls">
                <div className="prev" onClick={prev}><img src={prevBtn} alt="" /></div>
                <div className="next" onClick={next}><img src={nextBtn} alt="" /></div>
            </div>}
                <motion.div className="gallery-modal-img" initial={{opacity:0}} animate={{opacity: 1}} exit={{opacity:0}} transition={{duration:0.3}} style={{backgroundColor: filtred[galleryIndex].color}}>
                    <div className="gallery-top-element" style={{backgroundColor:windowSize <699 && filtred[galleryIndex].color}} onClick={exit}>
                        <h2 className="gallery-modal-name" style={{backgroundColor: filtred[galleryIndex].color}}>{filtred[galleryIndex].type}</h2>
                        <img className="close" src={close} alt="" />
                    </div>
                        <motion.img style={{position: "absolute"}} onLoad={() => setLoading(false)} key={filtred[galleryIndex].name} src={`/gallery/${filtred[galleryIndex].name}.jpg`} alt="" initial={{opacity:0}} animate={{opacity:loading ? 0 : 1}} />
                        <motion.img src={`/gallery/${filtred[galleryIndex].name+1}.jpg`} style={{opacity:0}} alt="" />
                        <motion.img src={`/gallery/${filtred[galleryIndex].name-1}.jpg`} style={{opacity:0}} alt="" />
                </motion.div>
        </motion.div>
    );
  }
  
  export default GalleryModal;