import { motion,useInView } from 'framer-motion'
import {useState} from "react"
import {LinearModelData} from "../linear-data"
import {fadingIn} from "../myVariants"
import {Link} from "react-router-dom";
import ModalForLinear3 from './modal-for-linear3';


function Linear3({content}) {

    const [exampleVisible, setExampleVisible] = useState("")
    return (
        <div className="LinearModelComponent">
            <h2 className="section-header">{content.linear31}</h2>   
            <div className="bag-menu">
                {LinearModelData.list.map(item => {
                    return(
                            <Link to={`/projektuj/${item}`} key={item}>
                            <motion.div className='bag-item glass shadow' variants={fadingIn} initial="hidden" whileInView="show" viewport={{ once: true }} title={item}>
                                <h3 className='bag-item-name'>{item}</h3>
                                <div className="linear-item" onClick={()=>setExampleVisible(item)}>
                                    <svg className='linear-with' version="1.1" viewBox="0 0 84.36 75.709"xmlns="http://www.w3.org/2000/svg">
                                        <g transform="translate(0 0)">
                                            <path d={LinearModelData[item].dd} color="#000000" fill="#fff"/>
                                        </g>
                                    </svg>
                                </div>
                                <button className='bag-item-info'>{content.linear32}</button>                     
                            </motion.div>   
                        </Link>
                    )
                })}      
            </div> 
        </div>
    );
  }
  
  export default Linear3;