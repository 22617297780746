import {motion} from "framer-motion"
import {Link} from "react-router-dom";
import {fadingIn} from "../../myVariants"
import {etsy,fb,proj} from "../../proj-or-fb-svg"


function ProjectOrFb({content}) {

    return (
      <motion.section className="ProjectOrFb" variants={fadingIn} initial="hidden" whileInView="show">
          <div className="proj-fb-etsy-item">
              <h3>{content.projFbEtsy1}</h3>
              <Link to="/projektuj">
                <button className="proj-fb-etsy-button">{content.projFbEtsy2}</button>
              </Link>
              <div className="proj-svg">
                {proj}
              </div>
          </div>
          <div className="proj-fb-etsy-item" >
              <h3>{content.projFbEtsy3}</h3>
              <Link to="https://www.facebook.com/zszytee"  target="_blank"> 
                <button className="proj-fb-etsy-button">{fb} {content.projFbEtsy4}</button>
              </Link>
              <Link to="https://www.etsy.com/shop/zszyte" target="blank">
                <button className="proj-fb-etsy-button">{etsy} {content.projFbEtsy5}</button>
              </Link>
          </div>
      </motion.section>
    );
  }
  
  export default ProjectOrFb;