export const galleryData = [
    {
        name:316,
        type: "kuferek",
        color:"#09233f"
    },
    {
        name:315,
        type: "kuferek",
        color:"#4f3646"
    },
    {
        name:314,
        type: "kuferek",
        color:"#1c2536"
    },
    {
        name:313,
        type: "kuferek",
        color:"#13264b"
    },
    {
        name:312,
        type: "kuferek",
        color:"#122448"
    },
    {
        name:311,
        type: "kuferek",
        color:"#061832"
    },
    {
        name:310,
        type: "kuferek",
        color:"#1c2d47"
    },
    {
        name:309,
        type: "kuferek",
        color:"#21355a"
    },
    {
        name:308,
        type: "ambasadorka",
        color:"#112044"
    },
    {
        name:307,
        type: "ambasadorka",
        color:"#6b2e38"
    },
    {
        name:306,
        type: "podróżniczka",
        color:"#662954"
    },
    {
        name:305,
        type: "podróżniczka",
        color:"#211122"
    },
    {
        name:304,
        type: "podróżniczka",
        color:"#1b1018"
    },
    {
        name:303,
        type: "podróżniczka",
        color:"#1f304c"
    },
    {
        name:302,
        type: "podróżniczka",
        color:"#819bd4"
    },
    {
        name:301,
        type: "podróżniczka",
        color:"#849ab1"
    },
    {
        name:300,
        type: "podróżniczka",
        color:"#021c50"
    },
    {
        name:299,
        type: "pani beczułka",
        color:"#a42e2c"
    },
    {
        name:298,
        type: "pani beczułka",
        color:"#BE4511"
    },
    {
        name:297,
        type: "pani beczułka",
        color:"#030847"
    },
    {
        name:296,
        type: "okruszek",
        color:"#86a8c8"
    },
    {
        name:295,
        type: "merka",
        color:"#3a483a"
    },
    {
        name:294,
        type: "merka",
        color:"#383637"
    },
    {
        name:293,
        type: "arystokratka",
        color:"#0f2731"
    },
    {
        name:292,
        type: "arystokratka",
        color:"#0c2e3c"
    },
    {
        name:291,
        type: "arystokratka",
        color:"#312c64"
    },
    {
        name:290,
        type: "podróżniczka",
        color:"#b5965f"
    },
    {
        name:289,
        type: "podróżniczka",
        color:"#252427"
    },
    {
        name:288,
        type: "arystokratka",
        color:"#0f1e19"
    },
    {
        name:287,
        type: "arystokratka",
        color:"#1e352d"
    },
    {
        name:286,
        type: "ambasadorka",
        color:"#8c623a"
    },
    {
        name:285,
        type: "ambasadorka",
        color:"#275338"
    },
    {
        name:284,
        type: "pan plecak",
        color:"#3d468a"
    },
    {
        name:283,
        type: "pan plecak",
        color:"#2e3158"
    },
    {
        name:282,
        type: "okruszek",
        color:"#95b6d4"
    },
    {
        name:281,
        type: "pani beczułka",
        color:"#790209"
    },
    {
        name:280,
        type: "pani beczułka",
        color:"#0c283f"
    }, 
    {
        name:279,
        type: "pani beczułka",
        color:"#859b99"
    }, 
    {
        name:278,
        type: "pani beczułka",
        color:"#738c94"
    }, 
    {
        name:277,
        type: "atomówka",
        color:"#2e353b"
    }, 
    {
        name:276,
        type: "atomówka",
        color:"#4e5b48"
    }, 
    {
        name:275,
        type: "atomówka",
        color:"#8fa594"
    }, 
    {
        name:274,
        type: "atomówka",
        color:"#31423c"
    }, 
    {
        name:273,
        type: "atomówka",
        color:"#263538"
    }, 
    {
        name:272,
        type: "ambasadorka",
        color:"#3f2a41"
    }, 
    {
        name:271,
        type: "ambasadorka",
        color:"#5f3c54"
    }, 
    {
        name:270,
        type: "ambasadorka",
        color:"#17061e"
    }, 
    {
        name:269,
        type: "ambasadorka",
        color:"#1a1f21"
    }, 
    {
        name:268,
        type: "ambasadorka",
        color:"#2b2d2c"
    }, 
    {
        name:267,
        type: "okruszek",
        color:"#013879"
    }, 
    {
        name:265,
        type: "okruszek",
        color:"#004cd2"
    }, 
    {
        name:264,
        type: "okruszek",
        color:"#061739"
    }, 
    {
        name:263,
        type: "nerka",
        color:"#1c2b34"
    }, 

    {
        name:262,
        type: "nerka",
        color:"#001c17"
    }, 
    {
        name:261,
        type: "pani beczułka",
        color:"#393870"
    }, 
    {
        name:260,
        type: "pani beczułka",
        color:"#192150"
    }, 
    {
        name:259,
        type: "okruszek",
        color:"#1d2426"
    }, 
    {
        name:258,
        type: "okruszek",
        color:"#000205"
    }, 
    {
        name:257,
        type: "ambasadorka",
        color:"#223047"
    }, 
    {
        name:256,
        type: "ambasadorka",
        color:"#1a1d30"
    }, 
    {
        name:255,
        type: "ambasadorka",
        color:"#4ca3b4"
    }, 
    {
        name:254,
        type: "okruszek",
        color:"#0266eb"
    }, 
    {
        name:253,
        type: "okruszek",
        color:"#070f19"
    }, 
    {
        name:252,
        type: "pani beczułka",
        color:"#4f4e51"
    }, 
    {
        name:251,
        type: "pani beczułka",
        color:"#af9fa4"
    }, 
    {
        name:250,
        type: "pani beczułka",
        color:"#033a37"
    }, 
    {
        name:249,
        type: "pani beczułka",
        color:"#003e3b"
    }, 
    {
        name:248,
        type: "pani beczułka",
        color:"#006864"
    }, 
    {
        name:247,
        type: "pani beczułka",
        color:"#004642"
    }, 
    {
        name:246,
        type: "pani beczułka",
        color:"#73493c"
    }, 
    {
        name:245,
        type: "pani beczułka",
        color:"#291d4f"
    }, 
    {
        name:244,
        type: "pani beczułka",
        color:"#b8755e"
    }, 
    {
        name:243,
        type: "pani beczułka",
        color:"#694135"
    }, 
    {
        name:242,
        type: "pani beczułka",
        color:"#4e2d28"
    }, 
    {
        name:241,
        type: "ambasadorka",
        color:"#cfc6b5"
    }, 
    {
        name:240,
        type: "okruszek",
        color:"#db7f9a"
    }, 
    {
        name:239,
        type: "okruszek",
        color:"#0079cf"
    }, 
    {
        name:238,
        type: "okruszek",
        color:"#04090d"
    }, 
    {
        name:237,
        type: "okruszek",
        color:"#4c6757"
    }, 
    {
        name:236,
        type: "aligatorka",
        color:"#885536"
    }, 
    {
        name:235,
        type: "okruszek",
        color:"#b61e11"
    }, 
    {
        name:234,
        type: "okruszek",
        color:"#963b38"
    }, 
    {
        name:233,
        type: "okruszek",
        color:"#1a1254"
    }, 
    {
        name:232,
        type: "okruszek",
        color:"#c5033b"
    }, 
    {
        name:231,
        type: "okruszek",
        color:"#716f6d"
    }, 
    {
        name:230,
        type: "okruszek",
        color:"#beb9b3"
    }, 
    {
        name:229,
        type: "nerka",
        color:"#01342e"
    }, 
    {
        name:228,
        type: "nerka",
        color:"#041917"
    }, 
    {
        name:227,
        type: "konduktorka",
        color:"#67112c"
    }, 
    {
        name:226,
        type: "konduktorka",
        color:"#fb1b6b"
    }, 
    {
        name:225,
        type: "okruszek",
        color:"#111820"
    }, 
    {
        name:224,
        type: "okruszek",
        color:"#0e2f84"
    }, 
    {
        name:223,
        type: "okruszek",
        color:"#031058"
    }, 
    {
        name:222,
        type: "okruszek",
        color:"#fbb004"
    }, 
    {
        name:221,
        type: "okruszek",
        color:"#10287a"
    }, 
    {
        name:220,
        type: "okruszek",
        color:"#1d4683"
    }, 
    {
        name:219,
        type: "okruszek",
        color:"#1a4372"
    }, 
    {
        name:218,
        type: "podróżniczka",
        color:"#a57a51"
    }, 
    {
        name:217,
        type: "okruszek",
        color:"#34263d"
    }, 
    {
        name:216,
        type: "okruszek",
        color:"#473c40"
    }, 
    {
        name:215,
        type: "okruszek",
        color:"#3d2340"
    }, 
    {
        name:214,
        type: "nerka",
        color:"#323431"
    }, 
    {
        name:213,
        type: "nerka",
        color:"#26272d"
    }, 
    {
        name:212,
        type: "okruszek",
        color:"#09403b"
    }, 
    {
        name:211,
        type: "okruszek",
        color:"#8a9354"
    }, 
    {
        name:210,
        type: "konduktorka",
        color:"#998e87"
    }, 
    {
        name:209,
        type: "konduktorka",
        color:"#76706c"
    }, 
    {
        name:208,
        type: "okruszek",
        color:"#1e1d22"
    }, 
    {
        name:207,
        type: "okruszek",
        color:"#64732e"
    },  
    {
        name:206,
        type: "okruszek",
        color:"#8e3e48"
    },  
    {
        name:205,
        type: "okruszek",
        color:"#0e5f6e"
    },  
    {
        name:204,
        type: "okruszek",
        color:"#093a4f"
    },  
    {
        name:203,
        type: "okruszek",
        color:"#210116"
    },  
    {
        name:202,
        type: "okruszek",
        color:"#9c8e83"
    },  
    {
        name:201,
        type: "okruszek",
        color:"#c0b4a8"
    },  
    {
        name:200,
        type: "okruszek",
        color:"#4c5046"
    },  
    {
        name:199,
        type: "konduktorka",
        color:"#af9386"
    },  
    {
        name:198,
        type: "konduktorka",
        color:"#683d18"
    },  
    {
        name:197,
        type: "konduktorka",
        color:"#cabeb0"
    },  
    {
        name:196,
        type: "debiutantka",
        color:"#5a2e15"
    },  
    {
        name:195,
        type: "debiutantka",
        color:"#bf9a87"
    },  
    {
        name:194,
        type: "debiutantka",
        color:"#988d80"
    },  
    {
        name:193,
        type: "ambasadorka",
        color:"#415a54"
    },  
    {
        name:192,
        type: "ambasadorka",
        color:"#385044"
    },  
    {
        name:191,
        type: "debiutantka",
        color:"#091a39"
    },  
    {
        name:190,
        type: "atomówka",
        color:"#b0b7b9"
    },  
    {
        name:189,
        type: "atomówka",
        color:"#a48d88"
    },  
        {
            name:188,
            type: "ambasadorka",
            color:"#043645"
        },    
        {
            name:187,
            type: "ambasadorka",
            color:"#70332a"
        },    
        {
            name:186,
            type: "ambasadorka",
            color:"#054455"
        },    
        {
            name:185,
            type: "ambasadorka",
            color:"#033451"
        },    
        {
            name:184,
            type: "pan plecak",
            color:"#153f75"
        },    
        {
            name:183,
            type: "pan plecak",
            color:"#234d7d"
        },    {
            name:182,
            type: "pan plecak",
            color:"#021c32"
        },    
        {
            name:181,
            type: "okruszek",
            color:"#1a4d42"
        },    
        {
            name:180,
            type: "okruszek",
            color:"#00221c"
        },    
        {
            name:179,
            type: "okruszek",
            color:"#0a2f27"
        },    
        {
            name:178,
            type: "pan plecak",
            color:"#efd5ba"
        },    
        {
            name:177,
            type: "pan plecak",
            color:"#ab5b72"
        },    
        {
            name:176,
            type: "atomówka",
            color:"#3a373a"
        },    
        {
            name:175,
            type: "atomówka",
            color:"#969ea1"
        },    
        {
            name:174,
            type: "atomówka",
            color:"#71708f"
        },
        {
            name:173,
            type: "podróżniczka",
            color:"#562c1e"
        },
        {
            name:172,
            type: "inne",
            color:"#321b24"
        },
        {
            name:171,
            type: "inne",
            color:"#a6d4ee"
        },
        {
            name:170,
            type: "pan plecak",
            color:"#17334e"
        },
        {
            name:169,
            type: "pan plecak",
            color:"#092f4a"
        },
        {
            name:168,
            type: "pan plecak",
            color:"#164369"
        },
        {
            name:167,
            type: "pan plecak",
            color:"#810417"
        },
        {
            name:166,
            type: "pan plecak",
            color:"#97091d"
        },
        {
            name:165,
            type: "nerka",
            color:"#757a7b"
        },
        {
            name:164,
            type: "okruszek",
            color:"#760006"
        },
        {
            name:163,
            type: "okruszek",
            color:"#c4e2ea"
        },
        {
            name:162,
            type: "arystokratka",
            color:"#754c39"
        },
        {
            name:161,
            type: "arystokratka",
            color:"#754833"
        },
        {
            name:160,
            type: "arystokratka",
            color:"#754833"
        },
        {
            name:159,
            type: "ambasadorka",
            color:"#0f476a"
        },
        {
            name:158,
            type: "komorniczka",
            color:"#303435"
        },
        {
            name:157,
            type: "komorniczka",
            color:"#212526"
        },
        {
            name:156,
            type: "ambasadorka",
            color:"#424a7a"
        },
        {
            name:155,
            type: "ambasadorka",
            color:"#443970"
        },
        {
            name:154,
            type: "ambasadorka",
            color:"#64767d"
        },
        {
            name:153,
            type: "komorniczka",
            color:"#89a3b2"
        },
        {
            name:152,
            type: "ambasadorka",
            color:"#2d3d74"
        },
        {
            name:151,
            type: "ambasadorka",
            color:"#849ddf"
        },
        {
            name:150,
            type: "kompaktówka",
            color:"#ced2d3"
        },
        {
            name:149,
            type: "konduktorka",
            color:"#3a3e41"
        },
        {
            name:148,
            type: "konduktorka",
            color:"#252f2e"
        },
        {
            name:147,
            type: "arystokratka",
            color:"#ad8c2f"
        },
        {
            name:146,
            type: "arystokratka",
            color:"#967929"
        },
        {
            name:145,
            type: "arystokratka",
            color:"#3e62cd"
        },
        {
            name:144,
            type: "arystokratka",
            color:"#2e6ce3"
        },
        {
            name:143,
            type: "ambasadorka",
            color:"#c7a86d"
        },
        {
            name:142,
            type: "ambasadorka",
            color:"#ddcb86"
        },
        {
            name:141,
            type: "nerka",
            color:"#0a3c30"
        },
        {
            name:140,
            type: "okruszek",
            color:"#de6177"
        },
        {
            name:139,
            type: "okruszek",
            color:"#aa6144"
        },
        {
            name:138,
            type: "komorniczka",
            color:"#4853d7"
        },
        {
            name:137,
            type: "okruszek",
            color:"#4a5477"
        },
        {
            name:136,
            type: "okruszek",
            color:"#4a4a79"
        },
        {
            name:135,
            type: "komorniczka",
            color:"#ecd492"
        },
        {
            name:134,
            type: "komorniczka",
            color:"#9f8f56"
        },
        {
            name:133,
            type: "komorniczka",
            color:"#c92c2c"
        },
        {
            name:132,
            type: "komorniczka",
            color:"#c22e36"
        },
        {
            name:131,
            type: "arystokratka",
            color:"#386062"
        },
        {
            name:130,
            type: "ambasadorka",
            color:"#92b174"
        },
        {
            name:129,
            type: "komorniczka",
            color:"#62cfe8"
        },
        {
            name:128,
            type: "okruszek",
            color:"#e4d4ba"
        },
        {
            name:127,
            type: "komorniczka",
            color:"#4a8b65"
        },
        {
            name:126,
            type: "ambasadorka",
            color:"#2480b8"
        },
        {
            name:125,
            type: "okruszek",
            color:"#b65f4a"
        },
        {
            name:124,
            type: "okruszek",
            color:"#ceb68d"
        },
        {
            name:123,
            type: "arystokratka",
            color:"#594f68"
        },
        {
            name:122,
            type: "arystokratka",
            color:"#cecdc9"
        },
        {
            name:121,
            type: "arystokratka",
            color:"#556c95"
        },
        {
            name:120,
            type: "arystokratka",
            color:"#43a094"
        },
        {
            name:119,
            type: "arystokratka",
            color:"#65494c"
        },
        {
            name:118,
            type: "komorniczka",
            color:"#98a6c0"
        },
        {
            name:117,
            type: "komorniczka",
            color:"#825259"
        },
        {
            name:116,
            type: "komorniczka",
            color:"#b68357"
        },
        {
            name:115,
            type: "komorniczka",
            color:"#aa1355"
        },
        {
            name:114,
            type: "ambasadorka",
            color:"#c1bda9"
        },
        {
            name:113,
            type: "okruszek",
            color:"#c2d0f1"
        },
        {
            name:112,
            type: "okruszek",
            color:"#730717"
        },
        {
            name:111,
            type: "okruszek",
            color:"#b1bbcb"
        },
        {
            name:110,
            type: "podróżniczka",
            color:"#cda5d5"
        },
        {
            name:109,
            type: "ambasadorka",
            color:"#884782"
        },
        {
            name:108,
            type: "podróżniczka",
            color:"#8c282d"
        },
        {
            name:107,
            type: "podróżniczka",
            color:"#c63149"
        },
        {
            name:106,
            type: "podróżniczka",
            color:"#3895e1"
        },
        {
            name:105,
            type: "konduktorka",
            color:"#656da0"
        },
        {
            name:104,
            type: "ambasadorka",
            color:"#272a31"
        },
        {
            name:103,
            type: "aligatorka",
            color:"#42bed1"
        },
        {
            name:102,
            type: "okruszek",
            color:"#363636"
        },
        {
            name:101,
            type: "okruszek",
            color:"#ce0946"
        },
        {
            name:100,
            type: "okruszek",
            color:"#afbcc9"
        },
        {
            name:99,
            type: "nerka",
            color:"#808a96"
        },
        {
            name:98,
            type: "ambasadorka",
            color:"#4f7f83"
        },
        {
            name:97,
            type: "okruszek",
            color:"#387acc"
        },
        {
            name:96,
            type: "ambasadorka",
            color:"#2d2d2d"
        },
        {
            name:95,
            type: "ambasadorka",
            color:"#157633"
        },
        {
            name:94,
            type: "ambasadorka",
            color:"#3c2f95"
        },
        {
            name:93,
            type: "ambasadorka",
            color:"#c67b99"
        },
        {
            name:92,
            type: "ambasadorka",
            color:"#2550c0"
        },
        {
            name:91,
            type: "okruszek",
            color:"#ec164d"
        },
        {
            name:90,
            type: "okruszek",
            color:"#ca1e46"
        },
        {
            name:89,
            type: "ambasadorka",
            color:"#6c51a2"
        },
        {
            name:88,
            type: "aligatorka",
            color:"#313131"
        },
        {
            name:87,
            type: "ambasadorka",
            color:"#21614d"
        },
        {
            name:86,
            type: "okruszek",
            color:"#393d46"
        },
        {
            name:85,
            type: "podróżniczka",
            color:"#c82f53"
        },
        {
            name:84,
            type: "podróżniczka",
            color:"#daca8b"
        },
        {
            name:83,
            type: "aligatorka",
            color:"#a1bbd1"
        },
        {
            name:82,
            type: "aligatorka",
            color:"#2030a3"
        },
        {
            name:81,
            type: "konduktorka",
            color:"#cfc5c8"
        },
        {
            name:80,
            type: "nerka",
            color:"#389f3b"
        },
        {
            name:79,
            type: "podróżniczka",
            color:"#3a4ea4"
        },
        {
            name:78,
            type: "aligatorka",
            color:"#393b48"
        },
        {
            name:77,
            type: "aligatorka",
            color:"#852c5a"
        },
        {
            name:76,
            type: "nerka",
            color:"#90cfee"
        },
        {
            name:75,
            type: "ambasadorka",
            color:"#60677b"
        },
        {
            name:74,
            type: "ambasadorka",
            color:"#61699a"
        },
        {
            name:73,
            type: "ambasadorka",
            color:"#aeb4cb"
        },
        {
            name:72,
            type: "okruszek",
            color:"#cca971"
        },
        {
            name:71,
            type: "kompaktówka",
            color:"#ba51a2"
        },
        {
            name:70,
            type: "nerka",
            color:"#665ca3"
        },
        {
            name:69,
            type: "nerka",
            color:"#d5b791"
        },
        {
            name:68,
            type: "aligatorka",
            color:"#5fc27f"
        },
        {
            name:67,
            type: "ambasadorka",
            color:"#445165"
        },
        {
            name:66,
            type: "ambasadorka",
            color:"#ac6e3f"
        },
        {
            name:65,
            type: "ambasadorka",
            color:"#8c1e36"
        },
        {
            name:64,
            type: "ambasadorka",
            color:"#c58fbc"
        },
        {
            name:63,
            type: "nerka",
            color:"#e0cb88"
        },
        {
            name:62,
            type: "nerka",
            color:"#892c50"
        },
        {
            name:61,
            type: "nerka",
            color:"#47a9f5"
        },
        {
            name:60,
            type: "nerka",
            color:"#e26992"
        },
        {
            name:59,
            type: "inne",
            color:"#118fa4"
        },
        {
            name:58,
            type: "inne",
            color:"#44bce4"
        },
        {
            name:57,
            type: "ambasadorka",
            color:"#679585"
        },
        {
            name:56,
            type: "ambasadorka",
            color:"#b16237"
        },
        {
            name:55,
            type: "ambasadorka",
            color:"#b4ade3"
        },
        {
            name:54,
            type: "ambasadorka",
            color:"#a41636"
        },
        {
            name:53,
            type: "konduktorka",
            color:"#506db3"
        },
        {
            name:52,
            type: "inne",
            color:"#7d77ad"
        },
        {
            name:51,
            type: "ambasadorka",
            color:"#a6929d"
        },
        {
            name:50,
            type: "konduktorka",
            color:"#1f285e"
        },
        {
            name:49,
            type: "konduktorka",
            color:"#3f7d72"
        },
        {
            name:48,
            type: "konduktorka",
            color:"#722c46"
        },
        {
            name:47,
            type: "konduktorka",
            color:"#b3283c"
        },
        {
            name:46,
            type: "ambasadorka",
            color:"#a07356"
        },
        {
            name:45,
            type: "ambasadorka",
            color:"#4e6caa"
        },
        {
            name:44,
            type: "kompaktówka",
            color:"#a92d51"
        },
        {
            name:43,
            type: "kompaktówka",
            color:"#624be5"
        },
        {
            name:42,
            type: "kompaktówka",
            color:"#555869"
        },
        {
            name:41,
            type: "kompaktówka",
            color:"#52617e"
        },
        {
            name:40,
            type: "kompaktówka",
            color:"#edc26e"
        },
        {
            name:39,
            type: "kompaktówka",
            color:"#73a36e"
        },
        {
            name:38,
            type: "kompaktówka",
            color:"#e75077"
        },
        {
            name:37,
            type: "konduktorka",
            color:"#3a47a5"
        },
        {
            name:36,
            type: "konduktorka",
            color:"#e5853f"
        },
        {
            name:35,
            type: "konduktorka",
            color:"#9e1b38"
        },
        {
            name:34,
            type: "podróżniczka",
            color:"#af3656"
        },
        {
            name:33,
            type: "nerka",
            color:"#222c42"
        },
        {
            name:32,
            type: "kompaktówka",
            color:"#329c70"
        },
        {
            name:31,
            type: "kompaktówka",
            color:"#2535b6"
        },
        {
            name:30,
            type: "kompaktówka",
            color:"#5ace89"
        },
        {
            name:29,
            type: "ambasadorka",
            color:"#5a9ac4"
        },
        {
            name:28,
            type: "ambasadorka",
            color:"#406665"
        },
        {
            name:27,
            type: "ambasadorka",
            color:"#29256b"
        },
        {
            name:26,
            type: "nerka",
            color:"#c4dbfc"
        },
        {
            name:25,
            type: "ambasadorka",
            color:"#32a650"
        },
        {
            name:24,
            type: "ambasadorka",
            color:"#8a9aa9"
        },
        {
            name:23,
            type: "ambasadorka",
            color:"#b53ccf"
        },
        {
            name:22,
            type: "ambasadorka",
            color:"#ff3d7d"
        },
        {
            name:21,
            type: "konduktorka",
            color:"#ad9aad"
        },
        {
            name:20,
            type: "inne",
            color:"#a8d6ee"
        },
        {
            name:19,
            type: "konduktorka",
            color:"#c0226f"
        },
        {
            name:18,
            type: "ambasadorka",
            color:"#27695d"
        },
        {
            name:17,
            type: "inne",
            color:"#536c59"
        },
        {
            name:16,
            type: "nerka",
            color:"#9495a6"
        },
        {
            name:15,
            type: "konduktorka",
            color:"#cbba90"
        },
        {
            name:14,
            type: "konduktorka",
            color:"#af995c"
        },
        {
            name:13,
            type: "ambasadorka",
            color:"#282b36"
        },
        {
            name:12,
            type: "kompaktówka",
            color:"#e59fad"
        },
        {
            name:11,
            type: "kompaktówka",
            color:"#ba3a52"
        },
        {
            name:10,
            type: "kompaktówka",
            color:"#1c294d"
        },
        {
            name:9,
            type: "kompaktówka",
            color:"#4f919f"
        },
        {
            name:8,
            type: "kompaktówka",
            color:"#ea3756"
        },
        {
            name:7,
            type: "kompaktówka",
            color:"#252728"
        },
        {
            name:6,
            type: "kompaktówka",
            color:"#550d17"
        },
        {
            name:5,
            type: "kompaktówka",
            color:"#4a6e39"
        },
        {
            name:4,
            type: "inne",
            color:"#28345a"
        },
        {
            name:3,
            type: "kompaktówka",
            color:"#21242b"
        },
        {
            name:2,
            type: "inne",
            color:"#1b263c"
        },
        {
            name:1,
            type: "nerka",
            color:"#f12a65"
        },
    ]