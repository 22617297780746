


function Footer() {
  const copyYear = new Date().getFullYear()
    return (
      <div className="Footer">
          <div className="social-media-wrapper">
              <a href="https://www.facebook.com/zszytee" target="_blank">
              <svg version="1.1" viewBox="0 0 53.988 53.988" xmlns="http://www.w3.org/2000/svg"><style/><g transform="translate(-399.79 -152.32)"><path d="m411.44 152.32c-6.4534 0-11.649 5.1955-11.649 11.649v30.691c0 6.4534 5.1955 11.649 11.649 11.649h30.691c6.4534 0 11.648-5.1955 11.648-11.649v-30.691c0-6.4534-5.195-11.649-11.648-11.649zm18.492 7.0941c1.0468 0.0109 2.0391 0.0982 2.0391 0.0982h4.1951v6.6745h-4.1005s-1.2204 0.0795-1.6536 0.45218c-0.4309 0.3707-0.6904 1.5591-0.6904 1.5591v4.3542h6.4916l-0.82838 6.6745h-5.6632v19.989h-8.1447v-19.989h-4.2199v-6.6745h4.2199v-3.1507s-0.047-2.04 0.0946-3.0458c0.14269-1.0137 0.29731-2.0611 0.75549-2.9766 0.4712-0.94139 1.1302-1.8419 1.9844-2.4572 1.0911-0.78595 2.4568-1.1709 3.7801-1.4097 0.46495-0.0839 1.1124-0.1052 1.7405-0.0982z" fill="#fff"/></g></svg>
              </a>

              <a href="https://www.instagram.com/zszyte/" target="_blank">
              <svg version="1.1" viewBox="0 0 53.988 53.988" xmlns="http://www.w3.org/2000/svg"><style/><g transform="translate(-389.01 16.859)"><path d="m400.66-16.859c-6.4534 0-11.649 5.1955-11.649 11.649v30.691c0 6.4534 5.1955 11.649 11.649 11.649h30.691c6.4534 0 11.649-5.1955 11.649-11.649v-30.691c0-6.4534-5.1955-11.649-11.649-11.649zm6.6771 7.0321h17.336c6.2251 0 11.293 5.0677 11.293 11.293v17.338c0 6.2252-5.0682 11.293-11.293 11.293h-17.336c-6.2251 0-11.293-5.0677-11.293-11.293v-17.338c0-6.2251 5.0677-11.293 11.293-11.293zm0 3.5993c-4.293 2e-5 -7.6931 3.4006-7.6931 7.6936v17.338c0 4.293 3.4001 7.693 7.6931 7.693h17.336c4.293 0 7.6936-3.4 7.6936-7.693v-17.338c0-4.293-3.4006-7.6936-7.6936-7.6936zm19.517 3.2215a2.3624 2.3624 0 0 1 2.3626 2.3621 2.3624 2.3624 0 0 1-2.3626 2.3622 2.3624 2.3624 0 0 1-2.3621-2.3622 2.3624 2.3624 0 0 1 2.3621-2.3621zm-10.848 2.9776c5.5914 0 10.162 4.5729 10.162 10.164 2e-5 5.5914-4.5708 10.162-10.162 10.162-5.5914 0-10.162-4.5708-10.162-10.162 0-5.5914 4.5708-10.164 10.162-10.164zm0 3.5998c-3.6458 0-6.5624 2.9186-6.5624 6.5644 0 3.6458 2.9166 6.5624 6.5624 6.5624 3.6458 0 6.5624-2.9166 6.5624-6.5624 0-3.6458-2.9166-6.5644-6.5624-6.5644z" fill="#fff"/></g></svg>
              </a>
          </div>
          <div className="copyright">
          &#169; {copyYear} zszyte
          </div>
      </div>
    );
}
  
  
  export default Footer;