import {motion,useScroll,useMotionValueEvent } from 'framer-motion';
import { useState } from 'react';


function HopUp({content}) {
  const [scrollPosition, setScrollPosition] = useState(0)
  const { scrollY } = useScroll();

    const hopToUp = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
    }
    useMotionValueEvent(scrollY, "change", (latest) => {
      setScrollPosition(latest)
    })
    return (
      <motion.div className="HopUp" onClick={hopToUp} initial={{y:200}} animate={{y: scrollPosition >700 ? 0 : 200}} transition={{duration:0.5}}>
         <p>{content.hop1}</p>
         <p>{content.hop2}</p>
         <p>{content.hop3}</p>
      </motion.div>
    );
  }
  
  export default HopUp;