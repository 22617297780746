export const commentsPl = [
    {
        name: "Natalia",
        review: 'Zakochałam się w moim "okruszku". Jest cudny i perfekcyjnie wykonany, z dbałością o szczegóły. Na pewno jeszcze kupię kiedyś jakąś torebkę i będę polecać zszyte każdemu!'
    },
    {
        name: "Aleksandra",
        review: 'Torebka jest wykonana PERFEKCYJNIE ❤️ Jest idealnie dopracowana z dbałością o najmniejszy szczegół. Serdecznie polecam każdej babeczce, która jeszcze "dojrzewa" do zakupu 😉Duży plus za błyskawiczną wysyłkę.'
    },
    {
        name: "Ines",
        review: 'Serdecznie polecam, jakoś wykonania naprawdę wysoka. Super kontakt, szybka wysyłka. 🥰 Torebka jest prześliczna. 🤩'
    },
    {
        name: "Monika",
        review: 'Oczywiście że Polecam!!!! Torebka mega 😊pierwsza ale nie ostatnia😁kontakt z przemiłą właścicielką bardzo szybki, wysyłka expresowa☺️'
    },
    {
        name: "Aleksandra",
        review: 'Polecam!! Zamówiłam (póki co😄) 4 torebki i nerkę, wszystkie cudowne 😍3 torebki były prezentami, wszystkie właścicielki mega zadowolone. Myślę, że jeszcze tu wrócę po kolejne zakupy 😃🤩🤩'
    },
    {
        name: "Liwia",
        review: 'Kamila ma taka ogromna pasje! Wspanialy kontakt, wszystko doradzi i wyczaruje takie torebki, ktore spelnia oczekiwania nawet bardzo wymagajacych klientek! Bardzo polecam i na pewno jeszcze nie raz zamowie.'
    },
    {
        name: "Agata",
        review: 'Przepiękne torebki! Wykonane z dokładnością i widać, że włożono w nie dużo serca! Jestem zachwycona! Zamówiłam dwie torebki w piwonie i każda przypadła mi bardzo do gustu! Bardzo miły kontakt! Polecam serdecznie'
    },
    {
        name: "Aneta",
        review: 'Super kontakt, szybka wysyłka- torebka jest cudna! 🤩 Będę polecać- bo warto!! Chętnie zamówię kolejne, czekam z niecierpliwością na nowe wzory ♥️ Rewelacja, oby tak dalej 💕'
    }
]

export const commentsEn = [
    {
        name: "Natalia",
        review: 'I fell in love with "okruszek". It is wonderful, perfectly made with care for details. For sure I will buy another bag someday and I will recommend "zszyte" to everyone!'
    },
    {
        name: "Aleksandra",
        review: 'The bag is PERFECTLY made ❤️ It is perfectly polished with care for the smallest detail. I heartily recommend it to everyone who still hesitate 😉 Big plus for express shipping.'
    },
    {
        name: "Ines",
        review: 'I heartily recommend, really high quality. Great contact, fast shipping. 🥰 The bag is so lovely. 🤩'
    },
    {
        name: "Monika",
        review: 'Of course I recommend it!!!! Great bag 😊First but no last😁 Super fast contact with a lovely owner, express shipping'
    },
    {
        name: "Aleksandra",
        review: 'I recommend it (for now😄) 4 bags and kidney bag, all wonderful 😍3 bags were gifts, all owners are super happy. I think I will come back here to buy more 😃🤩🤩'
    },
    {
        name: "Liwia",
        review: 'Kamila has such a great passion! Great contact, She will advise and conjure up bags which meets the expectations of even the most demanding customers! I highly recommend and I will definitely order again.'
    },
    {
        name: "Agata",
        review: 'Beautiful bags! Made with precision and you can see a lot of heart has been put into it! I am delighted! I ordered two peony pattern handbags and I liked each one very much! Very nice contact! I heartily recommend'
    },
    {
        name: "Aneta",
        review: 'Great contact, fast shipping- the bag is wonderfull! 🤩 I will recommend it - because it is worth it!! I would love to order another, I am waiting for new patterns ♥️ Revelation, keep it up💕'
    }
]