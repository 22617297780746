import { motion } from "framer-motion"
import WelurThumb from "../../img/welur-thumb.png"
import WodoodpornaThumb from "../../img/wodoodporna-thumb.png"
import NiciThumb from "../../img/nici-thumb.png"
import {fadingIn} from "../../myVariants"


function NiciMaterialy({content}) {

  
const infoData = [
  {
    name: content.niciMaterialy2,
    thumb: WodoodpornaThumb,
    info: [content.niciMaterialy3, content.niciMaterialy4]
  },
  {
    name: content.niciMaterialy5,
    thumb: WelurThumb,
    info: [content.niciMaterialy6, content.niciMaterialy7]
  },
  {
    name: content.niciMaterialy8,
    thumb: NiciThumb,
    info: [content.niciMaterialy9]
  },
]

    return (
      <section className="NiciMaterialy">
        <h2 className="section-header">{content.niciMaterialy1}</h2>
        <div className="nici-materialy-wrapper">
        {infoData.map((item) => {
          return(
            <motion.div className="nici-materialy-item" key={item.name} variants={fadingIn} initial="hidden" whileInView="show">
              <h3 className="nici-materialy-header glass">{item.name}</h3>
              <img className="nici-materialy-img" src={item.thumb} alt={item.name} />
                <div className="nici-materialy-info glass">
                  <p>{item.info[0]}</p>
                  <p>{item.info[1]}</p>
                </div>
            </motion.div>
          )
        })}
        </div>
      </section>
    );
  }
  
  export default NiciMaterialy;