import {useState } from "react";
import { motion } from "framer-motion"
import {AnimatePresence} from "framer-motion"
import GalleryModal from "./GalleryModal"


function PerelkiFiltred({displayPerelki, windowSize}) {
    // const displayPerelki = id === "wszystkie" ? galleryData : galleryData.filter((item) => item.type === id)

    
    const [galleryIndex, setGalleryIndex] = useState(-1);
    return (
      <section className="PerelkiFiltred">

          
          <motion.div className="uszyte-perelki-wrapper">
            <AnimatePresence>
              {displayPerelki.map((item, index)=>{
                    return(
                      <motion.div className="uszyte-perelki-item" onClick= {()=>setGalleryIndex(index)} key={item.name} layout initial={{opacity:0}} animate={{opacity:1}} exit={{opacity:0}} transition={{duration:0.5}} style={{backgroundColor: item.color}}>
                        <img loading="lazy" className="shadow" src={`/gallery/thumb/${item.name}.jpg`} alt="" />
                    </motion.div>
                    )
                })}
            </AnimatePresence>
          </motion.div>
          <AnimatePresence>
            {galleryIndex > -1 && <GalleryModal galleryIndex={galleryIndex} setGalleryIndex={setGalleryIndex} filtred={displayPerelki} windowSize={windowSize} />}
          </AnimatePresence>
      </section>
    );
  }
  
  export default PerelkiFiltred;