import talizmanPhoto from "../img/helm-of-awe.png"

function Talizman() {
    return (
      <div className="Talizman">
        <img src={talizmanPhoto} alt=""/>
        <br/>
            <p>Ægishjálm er ég ber</p>
            <p>Milli Bruna Mer!</p>
            <br/>
            <p>Noszę Helm of Awe</p>
            <p>między moimi brwiami!</p>
      </div>
    );
  }
  
  export default Talizman;