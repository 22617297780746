import {useState} from "react"
import { motion, AnimatePresence } from 'framer-motion'
import close from "../img/close-x.svg"

function FabricModal({setShowModal,showModal, scrapMessage}) {
    const isScrap = typeof showModal === "object"
    const modal = isScrap ? showModal[0] : showModal

    const [loaded, setLoaded] = useState(false)

    let path;
    const whichPaht = () => {
        const welurRegex = /^wt/i;
        const wodoodpornyRegex = /^wod/i;
        if(welurRegex.test(modal) === true){
            path = `/materialy/welur/${modal}.jpg`
        }else if(wodoodpornyRegex.test(modal) === true){
            path = `/materialy/wodoodporne/${modal}.jpg`
        } 
    }
    whichPaht()

    const exit = () => {
        setShowModal(0)
    }

    return (
        <motion.div className="FabricModal" initial={{opacity:0}} animate={{opacity:1}} exit={{opacity:0}}>
            <motion.div className="fabric-modal-img" initial={{opacity:0}} animate={{opacity: loaded ? 1 : 0}} onLoad={() => setLoaded(true)} >
                <div className="close" onClick={exit}>
                    <img src={close} alt="" />
                </div>
                <motion.img src={path} alt="" />
                <h2 className="fabric-modal-name">{modal}</h2>
            </motion.div>
            {isScrap && <p style={{fontSize:"1rem", marginLeft:"10px", textAlign:"center", background: "rgb(134, 28, 28)", padding:"5px 15px", border:"2px solid white", borderRadius:"25px", position:"absolute", bottom:"10px"}}>{scrapMessage}</p>}
        </motion.div>
    );
  }
  
  export default FabricModal;