import {NavLink, Link} from "react-router-dom";
import {motion} from "framer-motion"
import {useState} from "react"
import {forNavContainer,forNavItem} from "../myVariants"
import Logo from "../img/logo-zszyte.svg"
import ToggleLang from "./ToggleLang"


function NavDesktop({navItems}) {
  return (
          <motion.ul className="nav-menu-desktop" variants={forNavContainer} initial="hidden" animate="show">
            {navItems.map((item)=> {
              return(
                <motion.li variants={forNavItem} key={item.name}> 
                  <NavLink style={({isActive}) => {
                    return isActive ? {borderBottom: "2px solid white" } : {}
                  }} 
                  to={item.path}>{item.name}</NavLink>
                </motion.li>
              )
            })}
          </motion.ul>
  );
}

function NavMobile({menuOpen, setMenuOpen, navItems}) {
  return (
          <motion.ul className="nav-menu-mobile" initial={{x:"100vw"}} animate={{x: menuOpen ? 0 : "100vw"}} transition={{type:"easeIn", duration: 0.3}}>
            {navItems.map((item)=>{
              return(
                <li key={item.name}>
                  <NavLink style={({isActive}) => {
                    return isActive ? {borderBottom: "3px solid white" } : {}
                  }}
                   to={item.path} onClick={()=> setMenuOpen(false)}>{item.name}</NavLink>
                  </li>
              )
            })}
          </motion.ul>
  );
}


function Nav({windowSize,content, lang, setLang}) {
  const [menuOpen, setMenuOpen] = useState(false);

  const navItems = [
    {name: content.nav1, path: "/"}, 
    // {name: content.nav4, path: "/dostepne-od-reki"}, 
    {name: content.nav2, path:"/projektuj"}, 
    {name: content.nav3, path:"/uszyte-perelki"}
  ]

    return (
      <nav>
              <Link to="/" className="nav-logo-wrap">
                <motion.img className="nav-logo" src={Logo} alt="zszyte-logo" />
              </Link>
              {windowSize > 669 ? 
              <NavDesktop navItems={navItems}/> 
              :
              <NavMobile menuOpen={menuOpen} setMenuOpen={setMenuOpen} navItems={navItems}/>}
              {windowSize < 669 && <div className={menuOpen ? "hamburger-x" : "hamburger"} onClick={()=>setMenuOpen(prev => !prev)}>
                <span className="top-bar bar"></span>
                <span className="middle-bar bar"></span>
                <span className="bottom-bar bar"></span>
                </div>}
                <ToggleLang setLang={setLang} lang={lang}/>
      </nav>
    );
  }
  
  export default Nav;