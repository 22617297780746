export const modele = [
    {
        name:"ambasadorka",
        image: "/modele-podglad/w-ambasadorka.png",
        info: ["Największa w moim arsenale"],
        firstColor: "#133f3eff",
        secondColor: "#195756ff"
    },
    {
        name:"aligatorka",
        image: "/modele-podglad/w-aligatorka.png",
        info: ["Spokojnie, nie gryzie..."],
        firstColor: "#6e9dc4ff",
        secondColor: "#83adcdff"
    },
    {
        name:"arystokratka",
        image: "/modele-podglad/w-arystokratka.png",
        info: ["Eleganckie rozwiązanie"],
        firstColor: "#21395fff",
        secondColor: "#28477bff"
    },
    {
        name:"konduktorka",
        image: "/modele-podglad/w-konduktorka.png",
        info: ["Nie za duża, nie za mała"],
        firstColor: "#9d819aff",
        secondColor: "#ac95a9ff"
    },
    {
        name:"podróżniczka",
        image: "/modele-podglad/w-podrozniczka.png",
        info: ["Idealna na tamburyn"],
        firstColor: "#e0b666ff",
        secondColor: "#e9c88bff"
    },
    {
        name:"kompaktówka",
        image: "/modele-podglad/w-kompaktowka.png",
        info: ["Pomieści najważniejsze rzeczy"],
        firstColor: "#1d2549ff",
        secondColor: "#26315eff"
    },
    {
        name:"okruszek",
        image: "/modele-podglad/w-okruszek.png",
        info: ["Może robić na drugi etat jako kosmetyczka"],
        firstColor: "#12171cff",
        secondColor: "#1e272eff"
    },
    {
        name:"nerka",
        image: "/modele-podglad/w-nerka.png",
        info: ["Musisz mieć chociaż jedną"],
        firstColor: "#868f9dff",
        secondColor: "#9ea6aeff"
    }
]