

function SocialMedia({displayForm,content}) {
    return (
        <div className="social-media-contact-wrapper">
       <a href="mailto:zszyte@hotmail.com" target="_blank" rel="noreferrer" className='social-media-contact'>
          <svg height="6mm" width="6mm" version="1.1" viewBox="0 0 53.988 53.989" xmlns="http://www.w3.org/2000/svg"><style/><path d="m11.649 0c-6.4534 0-11.649 5.1954-11.649 11.649v30.691c0 6.4534 5.1954 11.649 11.649 11.649h30.691c6.4534 0 11.648-5.1954 11.648-11.649v-30.691c0-6.4534-5.1949-11.649-11.648-11.649zm-4.1925 11.001h39.078a1.9877 2.0128 0 0 1 1.9849 2.0252v27.937a1.9877 2.0128 0 0 1-1.9849 2.0252h-39.078a1.9877 2.0128 0 0 1-1.9854-2.0252v-27.937a1.9877 2.0128 0 0 1 1.9854-2.0252zm5.2353 4.0354 14.296 12.771 14.308-12.771zm31.857 2.4582-10.645 9.5074 10.645 9.5074v-12.309zm-35.107 0.0031v19.011l10.64-9.5023zm21.459 12.186-2.5993 2.3218a1.9877 2.0128 0 0 1-2.6277 0l-2.5911-2.3156-10.361 9.263h28.547z" fill="#fff"/></svg>
          <p>zszyte@hotmail.com</p>
          </a>
          <a href="https://www.facebook.com/zszytee" target="_blank"  rel="noreferrer" className='social-media-contact'>
          <svg height="6mm" width="6mm" version="1.1" viewBox="0 0 53.988 53.988" xmlns="http://www.w3.org/2000/svg" ><g transform="translate(-399.79 -152.32)"><path d="m411.44 152.32c-6.4534 0-11.649 5.1955-11.649 11.649v30.691c0 6.4534 5.1955 11.649 11.649 11.649h30.691c6.4534 0 11.648-5.1955 11.648-11.649v-30.691c0-6.4534-5.195-11.649-11.648-11.649zm18.492 7.0941c1.0468 0.0109 2.0391 0.0982 2.0391 0.0982h4.1951v6.6745h-4.1005s-1.2204 0.0795-1.6536 0.45218c-0.4309 0.3707-0.6904 1.5591-0.6904 1.5591v4.3542h6.4916l-0.82838 6.6745h-5.6632v19.989h-8.1447v-19.989h-4.2199v-6.6745h4.2199v-3.1507s-0.047-2.04 0.0946-3.0458c0.14269-1.0137 0.29731-2.0611 0.75549-2.9766 0.4712-0.94139 1.1302-1.8419 1.9844-2.4572 1.0911-0.78595 2.4568-1.1709 3.7801-1.4097 0.46495-0.0839 1.1124-0.1052 1.7405-0.0982z" fill="#fff"/></g></svg>
          <p>www.facebook.com/zszytee</p>
          </a>

          <a href="https://www.instagram.com/zszyte/" target="_blank"  rel="noreferrer" className='social-media-contact'>
          <svg height="6mm" width="6mm" version="1.1" viewBox="0 0 53.988 53.988" xmlns="http://www.w3.org/2000/svg" ><g transform="translate(-389.01 16.859)"><path d="m400.66-16.859c-6.4534 0-11.649 5.1955-11.649 11.649v30.691c0 6.4534 5.1955 11.649 11.649 11.649h30.691c6.4534 0 11.649-5.1955 11.649-11.649v-30.691c0-6.4534-5.1955-11.649-11.649-11.649zm6.6771 7.0321h17.336c6.2251 0 11.293 5.0677 11.293 11.293v17.338c0 6.2252-5.0682 11.293-11.293 11.293h-17.336c-6.2251 0-11.293-5.0677-11.293-11.293v-17.338c0-6.2251 5.0677-11.293 11.293-11.293zm0 3.5993c-4.293 2e-5 -7.6931 3.4006-7.6931 7.6936v17.338c0 4.293 3.4001 7.693 7.6931 7.693h17.336c4.293 0 7.6936-3.4 7.6936-7.693v-17.338c0-4.293-3.4006-7.6936-7.6936-7.6936zm19.517 3.2215a2.3624 2.3624 0 0 1 2.3626 2.3621 2.3624 2.3624 0 0 1-2.3626 2.3622 2.3624 2.3624 0 0 1-2.3621-2.3622 2.3624 2.3624 0 0 1 2.3621-2.3621zm-10.848 2.9776c5.5914 0 10.162 4.5729 10.162 10.164 2e-5 5.5914-4.5708 10.162-10.162 10.162-5.5914 0-10.162-4.5708-10.162-10.162 0-5.5914 4.5708-10.164 10.162-10.164zm0 3.5998c-3.6458 0-6.5624 2.9186-6.5624 6.5644 0 3.6458 2.9166 6.5624 6.5624 6.5624 3.6458 0 6.5624-2.9166 6.5624-6.5624 0-3.6458-2.9166-6.5644-6.5624-6.5644z" fill="#fff"/></g></svg>
          <p>www.instagram.com/zszyte</p>
          </a>
          
          {displayForm && 
          <a href="#contact" className='social-media-contact'>
          <svg width="6mm" height="6mm" version="1.1" viewBox="0 0 53.988 53.989" xmlns="http://www.w3.org/2000/svg"><style/><path d="m11.649 0c-6.4534 0-11.649 5.1953-11.649 11.649v30.691c0 6.4534 5.1953 11.649 11.649 11.649h30.691c6.4534 0 11.648-5.1953 11.648-11.649v-30.691c0-6.4534-5.1948-11.649-11.648-11.649zm-4.3439 10.945h39.377a2.0002 2.0002 0 0 1 1.9999 1.9999v28.098a2.0002 2.0002 0 0 1-1.9999 1.9999h-20.889l-9.3731 5.5433a2.0002 2.0002 0 0 1-3.0174-1.7208v-3.8225h-6.0978a2.0002 2.0002 0 0 1-1.9999-1.9999v-28.098a2.0002 2.0002 0 0 1 1.9999-1.9999zm1.9999 3.9998v24.098h6.0978a2.0002 2.0002 0 0 1 1.9999 1.9999v2.3146l6.8265-4.0354a2.0002 2.0002 0 0 1 1.0175-0.27905h19.436v-24.098zm4.7889 3.1424h25.8a1 1 0 0 1 0.99994 0.99994 1 1 0 0 1-0.99994 0.99994h-25.8a1 1 0 0 1-1.0005-0.99994 1 1 0 0 1 1.0005-0.99994zm0 5.4085h25.8a1 1 0 0 1 0.99994 0.99994 1 1 0 0 1-0.99994 0.99993h-25.8a1 1 0 0 1-1.0005-0.99993 1 1 0 0 1 1.0005-0.99994zm0 5.4079h17.926a1 1 0 0 1 0.99994 0.99994 1 1 0 0 1-0.99994 0.99994h-17.926a1 1 0 0 1-1.0005-0.99994 1 1 0 0 1 1.0005-0.99994z" fill="#fff"/></svg>
          <p>{content.socialMedia2}</p>
          </a>}
      </div>
    );
  }
  
  export default SocialMedia;