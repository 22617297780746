import { motion } from "framer-motion"
import {Metlik,Serce,Reka, boho1, boho2, boho3} from "../../core-obj.js"
import {fadingIn} from "../../myVariants"


function Core({content}) {
  
const coreData = [
  {name: content.core2, img: Metlik, bg:boho3, about:content.core3, class:"out"},
  {name: content.core4, img: Serce, bg:boho2, about: content.core5, class:"fill"},
  {name: content.core6, img: Reka, bg:boho1, about: content.core7, class:"sprinkle"},
]
    return (
      <motion.section className="Core" variants={fadingIn} initial="hidden" whileInView="show">
          <h2 className="section-header">{content.core1}</h2>
          <div className="core-wrapper">
          {coreData.map((item) => {
              return(
                <div className={`core-item glass ${item.class}`} key={item.name}>
                    <div className="core-imgs">
                      {item.bg}
                      {item.img}
                    </div>
                    <h2>{item.name}</h2>
                    <p className="core-about">{item.about}</p>
                </div>
              )
          })}
          </div>
      </motion.section>
    );
  }
  
  export default Core;