import { motion } from "framer-motion";
import {fadingIn} from "../../myVariants";
import {bagsObj} from "../../bags-obj";
import { useLocation } from "react-router-dom";



function BagsToBuy({content}) {

    const {pathname} = useLocation()

    const isPage = pathname.startsWith("/dostepne-od-reki")

    if (bagsObj.length === 0 && !isPage) return null
    if (bagsObj.length === 0 && isPage) return (
        <div className="BagsToBuy page">
            <h2 className="section-header">{content.bagsToBuy4}</h2>
        </div>
    )

    return (
      <motion.div className={`BagsToBuy ${isPage ? "page" : ""}`} variants={fadingIn} initial="hidden" whileInView="show">
          <h2 className="section-header">{content.bagsToBuy1}</h2>
          <p>{content.bagsToBuy3}</p>
          <div className="bags-to-buy-container">
            {bagsObj.map(bag => {
                return(
                    <div key={bag.link} className="bags-to-buy-item">
                        <img src={`/bags-to-buy/${bag.name}.jpg`} alt={bag.name} />
                        <a href={bag.link} target="_blank">
                            <button>
                            {content.bagsToBuy2}
                            </button>
                        </a>
                        <div className="bags-to-buy-item-price">{bag.price}€</div>
                    </div>
                )
            })}
          </div>
      </motion.div>
    );
  }
  
  export default BagsToBuy;