import {useSearchParams} from "react-router-dom";
import PerelkiFiltred from "./PerelkiFiltred";
import {galleryData} from "./gallery-data"


function UszytePerelki({content,windowSize}) {
  const [searchParams, setSearchParams] = useSearchParams()

  const typeFilter = searchParams.get("type")

  const types = [ "ambasadorka", "atomówka", "pan plecak", "pani beczułka", "aligatorka", "arystokratka", "konduktorka", "podróżniczka", "debiutantka", "kuferek", "kompaktówka", "okruszek", "nerka", "inne"]

  const displayPerelki = typeFilter && typeFilter !== "wszystkie" ? galleryData.filter((item) => item.type === typeFilter) : galleryData;

  const handleFilterChange = (key, value) => {
    setSearchParams(prevParams => {
        if (value === null) {
            prevParams.delete(key)
        } else {
            prevParams.set(key, value)
        }
        return prevParams
    })
}

    return (
      <section className="UszytePerelki page">
          <h2 className="section-header">{content.uszytePerelki1}</h2>
          <div className="filtry">
            <span className="show-me">{content.uszytePerelki3}</span>
            <button onClick={() => {handleFilterChange("type", "wszystkie")}} style={typeFilter === "wszystkie" || !typeFilter ? {color:"#5d7597", backgroundColor: "#fff"} : {}}>{content.uszytePerelki4}</button>
            {types.map((item) => {
              return(
                <button key={item} onClick={() => {handleFilterChange("type", item)}} style={typeFilter == item ? {color:"#5d7597", backgroundColor: "#fff"} : {}}>{item === "inne" ? content.uszytePerelki5 : item}</button>
              )
            })}
          </div>
          <PerelkiFiltred displayPerelki={displayPerelki} windowSize={windowSize}/>
      </section>
    );
  }
  
  export default UszytePerelki;