import {Link} from "react-router-dom";
import { motion, useInView } from "framer-motion"
import {useRef} from 'react';
import {fadingIn} from "../../myVariants"
import SocialMedia from "../social-media";


function HowItWorks({content}) {
    const refPath = useRef(null)
    const PathInView = useInView(refPath)
    return (
      <section className="HowItWorks">
          <h2 className="section-header">{content.howItWorks1}</h2>
          <motion.div className="how-it-works-wrapper" ref={refPath} variants={fadingIn} initial="hidden" whileInView="show">
            <div className="step step1">
              <p className="glass">{content.howItWorks2}</p>
              </div>
            <div className="step step2">
              <p className="glass">{content.howItWorks3}</p>
              </div>
            <div className="step step3">
              <p className="glass">{content.howItWorks4}<SocialMedia displayForm={true} content={content}/></p>
              </div>
            <div className="step step4">
              <p className="glass">{content.howItWorks8}</p>
              </div>
            <div className="step step5"><p className="glass">{content.howItWorks9}</p></div>
          </motion.div>
      </section>
    );
  }
  
  export default HowItWorks;