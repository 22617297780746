import {useState} from "react"
import { motion, AnimatePresence } from 'framer-motion'
import {materiałyWod,materiałyWel} from "../materialy-obj"
import FabricModal from "./fabric-modal"
import {fadingIn} from "../myVariants"


function FabricsComponent({content}) {

    const [showModal,setShowModal] = useState(0)

    return (
      <div className="FabricsComponent">
        <h2 className=" material-type">{content.fabricsComponent1}({materiałyWod.length})</h2>
          <div className="wodoodporne-wrapper glass">
            {materiałyWod.map((item,index) => {
              const isScrap = typeof item === "object"
              const fabric = isScrap ? item[0] : item
                return(
                    <motion.div className="material-wrapper shadow" key={fabric}
                    variants={fadingIn} initial="hidden" whileInView="show" viewport={{ once: true }}>
                        <img src={`/materialy/wodoodporne/wodoodporne-thumb/${fabric}.png`} alt={`materiał-wodoodporny-${fabric}`} onClick= {()=>setShowModal(item)}/>
                        
                        {isScrap && <p className="scrap" title={content.scrap2}>{content.scrap1} *</p>}
                        <p className="material-name">{fabric}</p>
                    </motion.div>
                )
            })}
            <p className="highlight" style={{fontSize:".9rem", width:"100%", marginLeft:"10px"}}>*{content.scrap2}</p>
          </div>
          <h2 className=" material-type">{content.fabricsComponent2}({materiałyWel.length})</h2>
          <div className="welur-wrapper glass">
            {materiałyWel.map((item,index) => {
              const isScrap = typeof item === "object"
              const fabric = isScrap ? item[0] : item
                return(
                    <motion.div className="material-wrapper shadow" key={fabric} variants={fadingIn} initial="hidden" whileInView="show" viewport={{ once: true }}>
                        <img src={`/materialy/welur/welur-thumb/${fabric}.png`} alt={`materiał-welur-tapicerski-${fabric}`} onClick= {()=>setShowModal(item)}/>
                        {isScrap && <p className="scrap" title={content.scrap2}>{content.scrap1} *</p>}
                        <p className="material-name">{fabric}</p>
                    </motion.div>
                )
            })}
            <p className="highlight" style={{fontSize:".9rem", width:"100%", marginLeft:"10px"}}>*{content.scrap2}</p>
          </div>
          <AnimatePresence>
            {showModal && <FabricModal showModal={showModal} setShowModal={setShowModal} scrapMessage={content.scrap2}/>}
          </AnimatePresence>
      </div>
    );
  }
  
  export default FabricsComponent;