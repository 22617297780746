import { motion, AnimatePresence } from "framer-motion"
import {useState, useRef, useEffect} from 'react';
import {Link} from "react-router-dom";
import {fadingIn} from "../../myVariants"
import {commentsPl, commentsEn} from "../../comments-obj"
import prevBtn from "../../img/prev-arrow.svg"
import nextBtn from "../../img/next-arrow.svg"


function Reviews({content, lang,windowSize}) {
  const [commentWidth, setCommentWidth] = useState(0)
  const review = useRef()
  const comment = useRef()
  const [reviewPosition, setReviewPosition] = useState(0)
  const [lengthToSwipe, setLengthToSwipe] = useState(0)
  const [disableButton, setDisableButton] = useState(false)
  

  let comments = lang === "pl" ? commentsPl : commentsEn;


  useEffect(() => {
    setCommentWidth(review.current.scrollWidth - review.current.offsetWidth)
    setLengthToSwipe( comment.current.scrollWidth * 2 + 50 * 2)
    setReviewPosition(0)
  }, [windowSize])

  useEffect(() => {
    if(reviewPosition > commentWidth){
      setDisableButton(true)
    }else if(reviewPosition < commentWidth){
      setDisableButton(false)
    }
  }, [reviewPosition])


  const next = () => {
    setReviewPosition(prev => prev + lengthToSwipe)
  }

  const prev = () => {
    setReviewPosition(prev => prev - lengthToSwipe)
  }


    return (
      <motion.div ref={review} className="Reviews" variants={fadingIn} initial="hidden" whileInView="show">
        <div className="review-header">
          <p>{content.review1}</p>
          <div className="review-header-stars">
          {
          [1,2,3,4,5].map((item) => {
            return(
              <svg className="review-star" width="7mm" version="1.1" viewBox="0 0 57.407 54.84" xmlns="http://www.w3.org/2000/svg" key={item}>
                <g transform="translate(184 -9.2747)">
                  <path transform="rotate(-6.1135 321.29 -223.8)" d="m-166.05 12.281c-2.6755 1.5447-12.985-10.399-16.058-10.722s-15.64 9.2166-17.936 7.1494c-2.2958-2.0672 5.8777-15.563 5.2354-18.585-0.64231-3.0219-13.599-12.027-12.342-14.849 1.2566-2.8223 16.618 0.78068 19.293-0.76401 2.6755-1.5447 7.2359-16.65 10.308-16.327s4.3928 16.046 6.6886 18.113c2.2958 2.0672 18.071 1.7368 18.713 4.7586 0.64232 3.0219-13.903 9.1362-15.16 11.958-1.2566 2.8223 3.9324 17.723 1.2569 19.268z" fill="#fff" stopColor="#000000" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
                </g>
             </svg>
            )
          })
        }
          <p>(5/5)</p>
          </div>
          <div>
           {lang === "en" && <p class="tanslate-info">(translated into English, originally in Polish)</p>}
          </div>
        </div>
          {windowSize > 669 &&
            <div style={{paddingRight: "50px"} }className="uni-controls">
                <div className={reviewPosition < 1 ? "disabled" : ""} onClick={prev}><img src={prevBtn} alt="" /></div>
                <div className={disableButton ? "disabled" : ""} onClick={next}><img src={nextBtn} alt="" /></div>
            </div>}
          <motion.div className="comments" drag="x" dragElastic={0.1} dragConstraints={{right: 0, left: -commentWidth}} dragMomentum={true}
dragTransition={{ timeConstant: 300, power: 0.3 }} initial={{x:0}} animate={{x:-reviewPosition }} transition={{duration: 1}}>
            {
              comments.map((item,index) => {
                return(
                  <motion.div className="comment glass" key={index} ref={comment}>
                    <p>{item.name}:</p>
                    <p>{item.review}</p>
                  </motion.div>

                )
              })
            }
          </motion.div>
      </motion.div>
    );
  }
  
  export default Reviews;