import {Outlet} from "react-router-dom";
import Linear3 from "./linear3"
import FabricsComponent from "./fabrics-component"
import ProjektujInfo from "./ProjektujInfo";


function Projektuj({content}) {


    return (

      <section className="Projektuj page">
          <div className="projektuj-wrapper">  
          <ProjektujInfo content={content} />
          <Linear3 content={content}/>
          <FabricsComponent content={content} />
          <Outlet/>
          </div>
      </section>

      
    );
  }
  
  export default Projektuj;