import HeroModele from "./homeComponents/HeroWithModels"
import NiciMaterialy from "./homeComponents/NiciMaterialy"
import ProjectOrFb from "./homeComponents/ProjectOrFb"
import Reviews from "./homeComponents/Reviews"
import HowItWorks2 from "./homeComponents/HowItWorks2"
import Core from "./homeComponents/Core"
import BagsToBuy from "./homeComponents/BagsToBuy"



function Home({windowSize, lang,content}) {
    return (
      <div className="Home">
        <HeroModele windowSize={windowSize} content={content} lang={lang}/>
        <ProjectOrFb content={content}/>
        <BagsToBuy content={content}/>
        <Reviews content={content} lang={lang} windowSize={windowSize}/>
        <HowItWorks2 content={content}/>
        <Core content={content} />
        <NiciMaterialy content={content} />
      </div>
    );
  }
  
  export default Home;