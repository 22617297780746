import {useState} from "react"


function UrlopModal({windowSize,setShowUrlop, lang, content}) {

    return (
      <div className="urlop-modal-container" >
          <div className="urlop-modal-window">
              <h2>{content.urlopModal1}</h2>
              <p>{content.urlopModal2}</p>
              <p>05.04.2023 - 18.04.2024</p>
              <button onClick={()=>setShowUrlop(false)}>{content.urlopModal3}</button>
          </div>
      </div>
    );
  }
  
  export default UrlopModal;